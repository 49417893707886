import { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolume, faStop } from "@fortawesome/pro-solid-svg-icons";
import { audioError } from "./audioError";
import { ttsTransform } from "./ttsTransform";
import { usePoc } from "../../PocContextProvider";
import { pocAssessmentFetchHelper } from "lib/api/pocAssessmentAPI";
import { postAssessmentEvent } from "lib/api/pocAssessmentAPI";

export const TextToSpeech = ({ text, id, domain }) => {
	const [hasLoaded, setHasLoaded] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const audioRef = useRef();
	const {
		playingAudioType,
		playingAudioId,
		currentContentIndex,
		registerPlayingAudio
	} = usePoc();

	useEffect(() => {
		const loadAudio = async () => {
			let audioSource = `data:audio/mp3;base64,${audioError}`;
			const transformedText = ttsTransform(text);
			if (transformedText) {
				const response = await pocAssessmentFetchHelper(`text_speech`, "POST", {
					text: transformedText
				});
				if (response.ok) {
					const result = await response.json();
					audioSource = result?.audioUrl;
				}
			}
			audioRef.current = new Audio(audioSource);
			audioRef.current.addEventListener("ended", handleEnded);
			setHasLoaded(true);
		};
		loadAudio();
	}, [text]);

	useEffect(() => {
		if (
			isPlaying &&
			playingAudioType != null &&
			(playingAudioType !== "tts" || playingAudioId !== id)
		) {
			handlePause();
		}
	});

	const handleEnded = () => setIsPlaying(false);

	const handlePause = () => {
		audioRef.current.pause();
		audioRef.current.currentTime = 0;
		handleEnded();
	};

	const handleClick = async () => {
		if (isPlaying) {
			handlePause();
		} else {
			audioRef.current.play();
			setIsPlaying(true);
			registerPlayingAudio("tts", id);
			await postAssessmentEvent("tts_played", currentContentIndex, domain);
		}
	};

	return (
		hasLoaded && (
			<button
				className="text-to-speech"
				onClick={handleClick}
				style={{ marginRight: "5px" }}>
				{isPlaying ? (
					<FontAwesomeIcon
						icon={faStop}
						size="xl"
						className="animate__animated animate__fadeIn tts-pause"
						style={{
							color: "#AAAAAA"
						}}
					/>
				) : (
					<FontAwesomeIcon
						icon={faVolume}
						size="xl"
						className="animate__animated animate__fadeIn tts-play"
						style={{
							color: "#AAAAAA"
						}}
					/>
				)}
			</button>
		)
	);
};
