export const pocAssessmentFetchHelper = async (
	endpointString,
	method,
	body = null,
	loginCode = null
) => {
	if (!loginCode) {
		loginCode = sessionStorage.getItem("loginCode");
	}
	const url = `${process.env.REACT_APP_API_ENDPOINT}-pocassessment/assessment/${endpointString}`;
	const response = await fetch(url, {
		method: method,
		headers: {
			"Content-Type": "application/json",
			Authorization: loginCode
		},
		body: body ? JSON.stringify(body) : null
	});
	if (response.status !== 200) {
		return { statusCode: response.status };
	}
	return response;
};

export const postAssessmentEvent = async (eventKey, itemNumber, domain) => {
	const loginCode = sessionStorage.getItem("loginCode");
	const url = `${process.env.REACT_APP_API_ENDPOINT}-pocassessment/assessment/poc_assessment_event`;
	const body = {
		eventKey: eventKey,
		itemNumber: itemNumber,
		domain: domain
	};
	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: loginCode
		},
		body: JSON.stringify(body)
	});
	return response.status;
};
