import React from "react";
import { Box, FormControl, Typography, TextInput } from "@ellevation/backpack";
import { usePoc } from "../../PocContextProvider";
import { faVolume } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Template3 = ({ question }) => {
	const { updateResponses, currentContentIndex, responses } = usePoc();

	const handleChange = event => {
		updateResponses(currentContentIndex, event.target.value);
	};

	return (
		<Box sx={{ mt: 4, width: "1131px", mx: "auto" }}>
			<Typography
				sx={{
					backgroundColor: "#F9F9F9",
					mt: 2,
					mx: 2,
					py: 2,
					px: 1,
					fontSize: "24px",
					textAlign: "center"
				}}
				variant="h4">
				{question.title}
			</Typography>
			<Box sx={{ display: "flex", flexDirection: "row" }}>
				<Box sx={{ ml: 2, mt: 3, mr: 12.5, width: "495px" }}>
					{question.textA && (
						<Typography sx={{ mt: 3, fontSize: "21px" }}>
							<Box component="span" sx={{ mr: 1 }}>
								<FontAwesomeIcon icon={faVolume} />
							</Box>
							{question.textA}
						</Typography>
					)}
					{question.stimulus && (
						<img
							style={{
								maxWidth: "100%",
								marginTop: "16px",
								borderRadius: "8px"
							}}
							src={question.stimulus}
							alt="stimulus image"
						/>
					)}
				</Box>
				<Box sx={{ mt: 3, width: "45%" }}>
					<FormControl component="fieldset">
						<Box sx={{ display: "flex", flexDirection: "row" }}>
							<Box
								sx={{ mr: 2, mt: "6px", fontSize: "21px", fontWeight: "600" }}>
								{question.questionNumber}
							</Box>
							<Typography sx={{ pr: 1, fontSize: "21px" }}>
								<Box component="span" sx={{ mr: 1 }}>
									<FontAwesomeIcon icon={faVolume} />
								</Box>
								{question.textB}
							</Typography>
						</Box>
						<Box sx={{ width: "100%" }}>
							<TextInput
								sx={{
									mt: 3,
									ml: 3,
									width: "93%",
									"& .MuiInputBase-root": { fontSize: "1rem" }
								}}
								value={
									responses[currentContentIndex]?.question?.response
										? responses[currentContentIndex]?.question?.response
										: ""
								}
								placeholder="Type your answer"
								onChange={handleChange}
								data-testid="writtenField"
								multiline
								minRows={10}
							/>
						</Box>
					</FormControl>
				</Box>
			</Box>
		</Box>
	);
};
